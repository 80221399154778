@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body {
        @apply h-screen overflow-hidden
    }
    .full-screen-wrapper {
        @apply flex items-center justify-center gap-3 h-screen bg-secondary
    }
    .wrapper {
        @apply flex items-center justify-center gap-3 bg-secondary
    }
    .content-box {
        @apply max-w-md mx-auto w-full flex flex-col items-end gap-10 bg-white/10 p-8 rounded-[26px] shadow-[0px_0px_8px_0px_rgba(66,66,66,0.10)];
    }
    .input-col-group {
        @apply flex flex-col gap-1 w-full
    }
    .input-row-group {
        @apply flex items-center gap-3
    }
    .input-label {
        @apply text-white pb-1 font-semibold leading-[normal] tracking-[-0.32px]
    }
    .input-field {
        @apply w-full px-3 py-3 text-sm rounded-lg border border-white bg-white text-black placeholder-black/70 focus:outline-none
    }
    .input-field-2 {
        @apply w-full px-3 py-3 text-sm rounded-lg border border-[#0A0846] bg-transparent text-[#0A0846] placeholder-[#0A0846]/70 focus:outline-none
    }
    
    .input-helper {
        @apply text-sm  text-white/50
    }
    .input-helper.input-error {
        @apply text-red-500
    }
    .card {
        @apply relative flex flex-col h-[210px] hover:shadow-theme transition-all duration-300 hover:-translate-y-1 justify-between border p-4 rounded-lg border-[rgba(255,255,255,0.20)] hover:border-primary;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
    }
    .btn {
        @apply flex items-center justify-center gap-3 font-medium py-3 px-7 rounded-lg w-full
    }
    .btn-primary {
        @apply bg-primary border border-primary text-white
    }
    .btn-outline-primary {
        @apply bg-transparent border border-primary text-primary
    }
    .btn-link {
        @apply text-primary hover:underline;
    }
    .btn-black {
        @apply bg-black text-white
    }
    .react-player {
        @apply w-full rounded-3xl !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-secondary;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-primary;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary/90;
  }

  @keyframes moveWave {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.soundwave {
    width: 100%;
    animation: moveWave 20s linear infinite; /* Adjust the duration and timing function as needed */
}